<template>
  <lims-block :cardText="false" class="list-page">
    <modal-select-user-types
      ref="selectUserTypesModal"
      slot="blockTitle"
      @onSelect="onSelectUserType"
    ></modal-select-user-types>
    <modal-delete-role
      ref="deleteRole"
      slot="blockTitle"
      @onCancel="$onCancelDeleteRole"
      @onDelete="onConfirmDeleteRole()"
    ></modal-delete-role>
    <div class="md-layout" slot="blockContent">
      <div class="md-layout-item" v-if="query">
        <account-role-list @action="onAction" :roles="items" @sort="sort = $event">
          <md-table-toolbar slot="toolbar">
            <div class="toolbar-row">
              <md-field class="field-btn">
                <md-button
                  v-if="$isAuthorized(['Accounts_RoleManagement_Create'])"
                  @click="openSelectRoleTypesModal"
                  class="btn-addRole"
                >
                  {{ $t('pages/Account/Role/button.addRole') }}
                </md-button>
              </md-field>
            </div>
            <div class="toolbar-row">
              <div class="field-rows-per-page">
                <lims-row-per-page :pageSize="query.pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
              </div>
              <div class="field-search">
                <RoleListFilter
                  v-model="query.filters"
                  :defaultValues="defaultValues"
                  :fields="fields"
                  @onResetFilters="onResetFilters"
                />
              </div>
            </div>
            <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"> </lims-pagination>
          </md-table-toolbar>
        </account-role-list>
        <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"> </lims-pagination>
      </div>
    </div>
  </lims-block>
</template>

<script>
import { ModalSelectUserTypes } from '@/components/Lims/modals';
import { APP_ROUTES, FORM_ACTIONS, NUMBER_OF_CHARACTERS_SEARCH, USER_TYPE_VALUES } from '@/core/constants';
import AccountRoleList from '@/pages/Account/Role/List/RoleList';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import RoleListFilter from '@/pages/Account/Role/List/RoleListFilter';
import LimsPagination from '@/components/Lims/LimsPagination';
import ModalDeleteRole from '@/components/Lims/modals/ModalDeleteRole';
import { buildFilterFromQuery, buildFilterQueryParams } from '@/query/utils';

import { roleQuery } from '@/query';
import { RoleService } from '@/services';
import { ConfigMixins, ListWithFilterMixins } from '@/core/mixins';
import cloneDeep from 'lodash/cloneDeep';
import roleMixins from './role.mixins';

export default {
  name: 'LimsAccountRole',
  mixins: [ConfigMixins, ListWithFilterMixins, roleMixins],
  components: {
    ModalDeleteRole,
    LimsRowPerPage,
    RoleListFilter,
    LimsPagination,
    AccountRoleList,
    ModalSelectUserTypes,
  },

  directives: {},

  created() {
    this.initState();
  },

  data() {
    return {
      items: [],
      totalItem: null,
      query: null,

      deletedId: '',
      deletedName: '',
    };
  },
  computed: {
    fields() {
      return roleQuery.filters.fields;
    },
    defaultValues() {
      return roleQuery.filters.defaultValues;
    },
  },
  watch: {},
  methods: {
    // common methods
    initState() {
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(roleQuery.filters, queryParams);
      const pagination = buildFilterFromQuery(roleQuery.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const sort = buildFilterFromQuery(roleQuery.sort, queryParams);
      const query = { filters, pagination, sort };
      this.query = query;
    },

    async search(queryObject) {
      const queryObjNew = cloneDeep(queryObject);
      if (queryObjNew.filters.search.length > 0 && queryObjNew.filters.search.length < NUMBER_OF_CHARACTERS_SEARCH) {
        return;
      }

      const { error, data } = await RoleService.search({
        filters: buildFilterQueryParams(queryObject.filters),
        pagination: buildFilterQueryParams(queryObject.pagination),
        sort: `${queryObject.sort.orderField}:${queryObject.sort.orderBy}`,
      });
      // detect error and show alert to user
      if (error) {
        this.$alertError(error);
        // remove loading screen and allow user to interact
        return;
      }

      // binding data
      this.items = data.items;
      this.totalItem = data.pagination.totalItems;

      // update router
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
        ...buildFilterQueryParams(queryObject.sort),
      };

      this.$router
        .push({
          query,
        })
        .catch((error) => {
          if (
            error.name === 'NavigationDuplicated' &&
            error.message.includes('Avoided redundant navigation to current location')
          ) {
            history.pushState({}, '', new URL(new URL(window.location)));
          }
        });
    },
    onResetFilters() {
      this.query.filters = cloneDeep(roleQuery.filters.defaultValues);
    },

    openSelectRoleTypesModal() {
      this.$refs.selectUserTypesModal.open();
    },

    // eslint-disable-next-line no-unused-vars
    onSelectUserType(formData) {
      // navigate
      if (formData.selectOption) {
        this.$router.push({
          name: 'RoleManagementAdd',
          params: {
            userType: USER_TYPE_VALUES[formData.selectOption.value - 1],
          },
        });

        this.$refs.selectUserTypesModal.close();
      }
    },
    deleteRow(item) {
      this.deletedId = item.roleId;
      this.deletedName = item.roleName;
      this.$onDeleteRole();
    },
    editRow(item) {
      this.$router.push(`${APP_ROUTES.ROLE_EDIT}/${item.roleId}`);
    },
    viewRow(item) {
      this.$router.push(`${APP_ROUTES.ROLE_VIEW}/${item.roleId}`);
    },
    onAction({ action, item }) {
      if (action === FORM_ACTIONS.EDIT) {
        this.editRow(item);
      }
      if (action === FORM_ACTIONS.VIEW) {
        this.viewRow(item);
      }
      if (action === FORM_ACTIONS.DELETE) {
        this.deleteRow(item);
      }
    },

    async onConfirmDeleteRole() {
      const res = await RoleService.delete(this.deletedId);
      if (res.err) {
        this.$alertError(res.err);
      } else {
        this.$onCancelDeleteRole();
        this.$queryChange(this.query);

        this.$alertSuccess(
          this.$t('pages/Account/Role/Forms/RoleForm/alert/delete', {
            roleName: this.deletedName,
          }),
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
