<template>
  <div>
    <md-table class="paginated-table table-striped table-hover lims-table user-tbl">
      <md-table-toolbar>
        <slot name="toolbar"></slot>
      </md-table-toolbar>
      <div class="table-fix-header">
        <thead>
          <md-table-row>
            <md-table-head v-for="(col, index) in colTabl" :key="index" :class="col.class">
              <div @mouseover="hoverIn(index)" @mouseout="hoverOut()" @click="sortCol(col.key, col.sort)">
                {{ $t(col.name) }}
                <span>
                  <md-icon v-if="col.sort">
                    {{
                      col.key === orderState.orderField
                        ? orderState.orderBy === 'asc'
                          ? 'arrow_drop_up'
                          : 'arrow_drop_down'
                        : hover === index
                        ? 'arrow_drop_up'
                        : ''
                    }}
                  </md-icon>
                </span>
              </div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row
            v-for="item in roles"
            :key="item.id"
            v-long-press="300"
            @click.native="onNavigate($event, item)"
            @long-press-start="onPlusStart"
            @long-press-stop="onPlusStop"
          >
            <md-table-cell class="id-column">{{ item.roleId }}</md-table-cell>
            <md-table-cell>{{ item.userType }}</md-table-cell>
            <md-table-cell>{{ item.roleName }}</md-table-cell>
            <md-table-cell class="users-column">{{ item.numberOfUser }}</md-table-cell>
            <md-table-cell>{{ item.lastUpdatedDate ? lastUpdatedDate(item.lastUpdatedDate) : '' }}</md-table-cell>
            <md-table-cell>{{ item.lastUpdatedByName ? item.lastUpdatedByName : '' }}</md-table-cell>
            <md-table-cell class="statusName-column">
              <md-icon
                :class="[
                  { 'status-enabled': item.roleStatusName === 'Enabled' },
                  { 'status-disabled': item.roleStatusName === 'Disabled' },
                ]"
                >adjust</md-icon
              >
            </md-table-cell>
            <md-table-cell class="cell-action">
              <div class="cell-action-inner">
                <div style="width: 33%">
                  <md-button
                    v-if="isShowViewIcon(item)"
                    class="md-just-icon md-info md-simple"
                    @click.native="handleAction('view', item)"
                  >
                    <md-icon>visibility</md-icon>
                  </md-button>
                </div>
                <div style="width: 33%">
                  <md-button
                    v-if="isShowEditIcon(item)"
                    class="md-just-icon md-warning md-simple"
                    @click.stop="handleAction('edit', item)"
                  >
                    <md-icon>edit</md-icon>
                  </md-button>
                </div>
                <div style="width: 33%">
                  <md-button
                    v-if="isShowDeleteIcon(item)"
                    class="md-just-icon md-danger md-simple"
                    @click.stop="handleAction('delete', item)"
                  >
                    <md-icon>delete_forever</md-icon>
                  </md-button>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
  </div>
</template>

<script>
import { ROLE_STATUSES } from '@/core/constants';
import { FormMixins } from '@/core/mixins';
import { DateTimeHelper } from '@/core/helpers';

export default {
  name: 'AccountRoleList',
  mixins: [FormMixins],
  props: {
    roles: {
      required: false,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      longPressMode: false,
      plusInterval: null,
      colTabl: [
        {
          name: 'pages/Account/Role/List/RoleList/column.id',
          key: 'id',
          sort: false,
          sortType: 'desc',
          class: 'id-column',
        },
        {
          name: 'pages/Account/Role/List/RoleList/column.usertype',
          key: 'userType',
          sort: true,
          sortType: 'asc',
          class: 'userType-column',
        },
        {
          name: 'pages/Account/Role/List/RoleList/column.role',
          key: 'roleName',
          sort: true,
          sortType: 'asc',
          class: 'roleName-column',
        },
        {
          name: 'pages/Account/Role/List/RoleList/column.users',
          key: 'users',
          sort: false,
          sortType: 'asc',
          class: 'users-column',
        },
        {
          name: 'pages/Account/Role/List/RoleList/column.lastUpdated',
          key: 'lastUpdated',
          sort: false,
          sortType: 'asc',
          class: 'lastUpdated-column',
        },
        {
          name: 'pages/Account/Role/List/RoleList/column.lastUpdatedBy',
          key: 'lastUpdatedBy',
          sort: false,
          sortType: 'asc',
          class: 'lastUpdatedBy-column',
        },
        {
          name: 'global/pages/list.status',
          key: 'statusName',
          sort: false,
          class: 'statusName-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          sort: false,
          class: 'actions-column',
        },
      ],
      hover: '',
      orderState: {
        orderField: 'userType',
        orderBy: 'asc',
      },
    };
  },

  watch: {
    orderState: {
      handler() {
        let sortType = this.orderState.orderField + ':' + this.orderState.orderBy;
        this.$emit('sort', sortType);
      },
      deep: true,
    },
  },
  methods: {
    handleAction(action, item) {
      this.$emit('action', {
        action,
        item,
      });
    },

    onNavigate(e, item) {
      if (!this.longPressMode) {
        e.preventDefault();
        this.handleAction('view', item);
      }
    },
    onPlusStart() {
      this.plusInterval = setInterval(() => {
        this.longPressMode = true;
      }, 100);
    },

    onPlusStop() {
      setTimeout(() => {
        this.longPressMode = false;
      }, 500);
      clearInterval(this.plusInterval);
    },

    hoverIn(index) {
      this.hover = index;
    },
    hoverOut() {
      this.hover = '';
    },
    sortCol(colName, isSort) {
      if (isSort) {
        if (this.orderState.orderField === colName) {
          if (this.orderState.orderBy === 'asc') {
            this.orderState.orderBy = 'desc';
          } else {
            this.orderState.orderBy = 'asc';
          }
        } else {
          this.orderState.orderField = colName;
          this.orderState.orderBy = 'asc';
        }
      }
    },

    lastUpdatedDate(date) {
      const { timezoneValue, dateTimeAppFormat } = this.$store.getters['config/$appConfig'];
      return DateTimeHelper.formatDateTimeGlobal(date, {
        timezoneValue,
        dateTimeAppFormat,
      });
    },

    isShowEditIcon(item) {
      return (
        item.roleStatusId === ROLE_STATUSES.Enable &&
        this.$isAuthorized(['Accounts_RoleManagement_Update']) &&
        item.isStatic === false
      );
    },

    isShowViewIcon(item) {
      return item.roleStatusId === ROLE_STATUSES.Disable || this.$isAuthorized(['Accounts_RoleManagement_Retrieve']);
    },

    isShowDeleteIcon(item) {
      return (
        item.roleStatusId === ROLE_STATUSES.Disable &&
        this.$isAuthorized(['Accounts_RoleManagement_Delete']) &&
        item.numberOfUser === 0 &&
        item.isStatic === false
      );
    },
  },
};
</script>
<style lang="scss">
.id-column {
  width: 80px !important;
}
.users-column {
  width: 80px !important;
  text-align: right;
  .md-table-cell-container {
    padding-right: 15px;
  }
}
</style>
